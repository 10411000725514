import Link from "next/link";

export default function NoMatch() {
	return (
		<div className="flex flex-wrap content-center h-screen items-center justify-center text-center">
			<h1 className="text-9xl font-dark font-bold">404</h1>
			<p className="text-2xl md:text-3xl font-light leading-normal">Page not found.</p>
			<div className="flex justify-center mt-6">
				<Link href="/">
					<a>Back to Home</a>
				</Link>
			</div>
		</div>
	);
}
